import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "components/auth/useAuth";
import styled, { keyframes } from "styled-components/macro";
import LabelledTextInput from "components/shared/LabelledTextInput";
import { Button } from "components/shared/standardElements";
import ImageMask from "components/shared/ImageMask";

import { useSpring, animated } from "react-spring";
import { COLORS, FONTSIZE } from "helpers/designConstants";

const Login = () => {
  console.log(process.env);

  const { i18n } = useTranslation();
  const pwRef = useRef();

  const [loginError, setLoginError] = useState(false);

  const logoStyles = useSpring({
    to: { translateY: "0" },
    from: { translateY: "-50vh" },
    config: { mass: 1, tension: 180, friction: 20 },
    delay: 400
  });

  const leftTextStyles = useSpring({
    to: { translateX: "0" },
    from: { translateX: "-60vw" },
    config: { mass: 1, tension: 180, friction: 20 },
    delay: 700
  });
  const rightTextStyles = useSpring({
    to: { translateX: "0" },
    from: { translateX: "60vw" },
    config: { mass: 1, tension: 180, friction: 20 },
    delay: 1000
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const { login } = useAuth();

  const didSelectLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const success = await login(pwRef.current.value);
    console.log(success);
    if (success) {
      navigate(state?.path || "/home");
    } else {
      setLoginError(true);
    }
  };

  return (
    <Page
      backgroundURL={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_ROOT}.appspot.com/o/sho%2Flogin%2Flogin_background.jpg?alt=media`}
    >
      <ImageMask maskOpacity={0.4} />
      <InputOverlay>
        <Content>
          <Logo>
            <animated.svg
              style={logoStyles}
              version='1.1'
              id='Layer_1'
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              viewBox='0 0 74.4 44'
              enableBackground='new 0 0 74.4 44'
            >
              <path
                fill='#FFFFFF'
                d='M0,39l2.9,5h19.6l-7.8-13.5L0,39z M33,44h15.5L31.9,15.5l-14.7,8.4L28.8,44H33z M54.7,44h19.7L48.9,0L34.2,8.5L54.7,44z'
              />
            </animated.svg>
            <TextWrapper>
              <AnimatedTextLeft style={leftTextStyles}>SS23</AnimatedTextLeft>
              <AnimatedTextRight style={rightTextStyles}>SHO</AnimatedTextRight>
            </TextWrapper>
          </Logo>
          <StyledForm onSubmit={handleLogin}>
            <InputWrapper>
              <StyledLabelledTextInput
                ref={pwRef}
                shouldAutoFocus
                type={"password"}
                labelText={i18n.language === "en" ? "PASSWORD" : "MOT DE PASSE"}
              />
              {loginError && <Error>* Please enter the correct password</Error>}
              <StyledButton type='submit'>
                {i18n.language === "en" ? "GO" : "ALLER"}
              </StyledButton>
            </InputWrapper>
          </StyledForm>
          <LanguageWrapper>
            <LeftLanguage
              selected={i18n.language === "en"}
              onClick={() => didSelectLanguage("en")}
            >
              ENGLISH
            </LeftLanguage>
            <RightLanguage
              selected={i18n.language === "fr-CA"}
              onClick={() => didSelectLanguage("fr-CA")}
            >
              FRANÇAIS
            </RightLanguage>
          </LanguageWrapper>
        </Content>
      </InputOverlay>
      <ExternalLinksWrapper>
        <ExternalLinkWrapper>
          <ExternalLinkHeader>
            {i18n.language === "en" ? "GO TO" : "ALLER À"}
          </ExternalLinkHeader>
          <ExternalLink
            onClick={() =>
              (window.location = `https://${
                process.env.REACT_APP_SUBDOMAIN.length > 0 ? "dev." : ""
              }myadidasevents.ca`)
            }
          >
            {i18n.language === "en" ? "EVENTS" : "EVENEMENTS"}
          </ExternalLink>
        </ExternalLinkWrapper>
        <ExternalLinkWrapper>
          <ExternalLinkHeader>
            {i18n.language === "en" ? "GO TO" : "ALLER À"}
          </ExternalLinkHeader>
          <ExternalLink
            onClick={() =>
              (window.location = `https://${
                process.env.REACT_APP_SUBDOMAIN.length > 0 ? "dev." : ""
              }myadidasbooking.ca`)
            }
          >
            {i18n.language === "en" ? "BOOKINGS" : "RÉSERVATIONS"}
          </ExternalLink>
        </ExternalLinkWrapper>
      </ExternalLinksWrapper>
    </Page>
  );
};

const Page = styled.div`
  margin: 0;
  //Heights here include fixes for mobile
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: all 1.5s;
  //End of Height fixes for mobile
  background-color: black;
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const InputOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: relative;
  height: 100%;
  /* width: 100%; */
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  display: grid;
  grid-template-rows: 0.333fr 0.333fr 0.333fr;
  /* padding: 50px; */
`;

const Logo = styled.div`
  /* position: absolute; */
  /* top: 0;
  left: 0;
  right: 0; */
  text-align: center;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 22vh;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    opacity: 0.8;
  }

  @media screen and (max-width: 1200px) {
    /* left: unset;
    right: 10vw; */
    svg {
      height: 18vh;
    }
  }
`;
const AnimatedLogo = animated(Logo);

const TextWrapper = styled.div`
  display: flex;
`;
const TextLeft = styled.div`
  padding-right: 1vw;
  font-family: "adiNeue PRO Condensed Black";
  font-size: 12vh;
  color: white;
  opacity: 0.8;
  text-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
  mix-blend-mode: screen;

  @media screen and (max-width: 1200px) {
    font-size: 10vh;
  }
`;
const AnimatedTextLeft = animated(TextLeft);

const TextRight = styled.div`
  padding-left: 1vw;
  font-family: "adiNeue PRO Condensed Black";
  font-size: 12vh;
  color: black;
  opacity: 0.8;
  text-shadow: 0px 0px 10px rgb(255 255 255 / 1);
  mix-blend-mode: screen;

  @media screen and (max-width: 1200px) {
    font-size: 10vh;
  }
`;
const AnimatedTextRight = animated(TextRight);

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vh;
  color: white;
  opacity: 0.8;
  text-shadow: 3px 5px 2px rgb(0 0 0 / 0.4);
  padding: 20px;
`;
const LeftLanguage = styled.div`
  padding: 20px 20px;
  text-decoration: ${({ selected }) => selected && "underline"};
  cursor: pointer;
`;
const RightLanguage = styled.div`
  padding: 20px 20px;
  text-decoration: ${({ selected }) => selected && "underline"};
  cursor: pointer;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
`;

const fadeInPassword = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  animation-name: ${fadeInPassword};
  animation-duration: 2s;
  /* animation-delay: 0.7s; */
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;

const StyledLabelledTextInput = styled(LabelledTextInput)`
  /* width: 300px; */
  text-shadow: 3px 2px 5px rgb(0 0 0 / 0.4);
`;

const Error = styled.div`
  color: white;
  padding: 5px;
  align-self: flex-start;
  text-transform: uppercase;
  font-size: 15px;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
  padding: 15px 30px 15px 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 3px 2px 5px rgb(0 0 0 / 0.4);
  font-size: 18px;
`;

const ExternalLinksWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  width: 100%;
  background: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
`;

const ExternalLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const ExternalLinkHeader = styled.div`
  font-size: ${FONTSIZE.medium};
  padding-bottom: 10px;
`;

const ExternalLink = styled.div`
  font-size: ${FONTSIZE.xlarge};

  :hover {
    color: ${COLORS.text};
  }
`;

export default Login;
